export const footersection = [
  { Hostings: [
    "Minecraft Hosting",
    "Docker Hosting",
    "High Performance Hosting",
    "Database Hosting",
    "Web Hosting"
  ], },
  { Resources: [
    "Domain",
    "Email Service",
    "Object Storage",
    "Rest APIs",
    "IP Public"
  ],},
  { Network: [
    "Virtual Private Network",
    "Border Gateway Protocol",
    "Reverse Proxy",
    "Cloud Based Router"
  ],},
  {Company: [
    "Contact",
    "Email Support",
    "Whatsapp Support",
    "Terms of Service",
    "Privacy Policy"
  ]},
];
