import { Database, Container, Server, ServerCrash, Earth, EthernetPort, Network, Inbox, Archive } from "lucide-react";

export const Menus = [
   {
        name: "Home",
   },
  {
    name: "Hosting",
    subMenuHeading: ["Game Hosting", "Other Hosting"],
    subMenu: [
      {
        name: "Minecraft Hosting",
        desc: "Advanced Minecraft Hosting",
        icon: Server,
      },
      {
        name: "Docker Hosting",
        desc: "Custom Docker Image",
        icon: Container,
      },
      {
        name: "High Performance",
        desc: "High Performance Hosting",
        icon: ServerCrash,
      },
      {
        name: "Database Hosting",
        desc: "SQL DB Hosting",
        icon: Database,
      },
    ],
    gridCols: 2,
  },
  {
    name: "Resources",
    subMenuHeading: ["Basic", "Network"],
    subMenu: [
      {
        name: "TLD",
        desc: "Top Level Domain",
        icon: Earth,
    },
    {
      name: "VPN",
      desc: "Your Custom Private Network",
      icon: EthernetPort,
    },
      {
        name: "Email Service",
        desc: "Make custom email with own domain",
        icon: Inbox,
      },
      {
        name: "Reverse Proxy",
        desc: "Proxy for your Server",
        icon: Network,
      },
      {
        name: "Object Storage",
        desc: "S3 Object Storage",
        icon: Archive,
      },
    ],
    gridCols: 2,
  },
  {
    name: "Pricing",
  },
  {
    name: "Contact",
  },
];