import Navbar from "../components/Navbar";
import HeaderHome from "../components/HeaderHome";
import Features from "../components/Features";
import Footer from "../components/Footer";

export default function HomePage() {
  return (
    <div>
      <Navbar />
      <HeaderHome />
      <Features />
      <Footer />
    </div>
  );
}