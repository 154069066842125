import { Cloud, Proportions, Package, Rabbit, CircleFadingArrowUp, ClockArrowUp, Rocket } from 'lucide-react';

export const featuresData = [
    {
      icon: <Rocket />,
      title: '99% Uptime',
      description: 'Stay online around the clock with our 99% uptime guarantee. We ensure your servers are always accessible, so you never miss an opportunity.',
    },
    {
      icon: <Proportions />,
      title: 'User-Friendly Management',
      description: 'Simplify your hosting experience with our intuitive control center. Manage your servers easily—no technical expertise required, except for changing server software (EGG CHANGER).',
    },
    {
      icon: <Package />,
      title: 'Effortless Installation',
      description: 'Launch your projects with ease using our Easy Installation feature. Whether it’s a Minecraft server or another application, get everything up and running in just a few clicks.',
    },
    {
      icon: <Rabbit />,
      title: 'High-Speed Performance',
      description: 'Experience blazing-fast performance with our optimized servers. We ensure minimal load times and a smooth experience for your users.',
    },
    {
      icon: <CircleFadingArrowUp />,
      title: 'Scalable Resources',
      description: 'Easily upgrade your hosting resources as your needs grow. Add storage, bandwidth, or processing power whenever necessary, without any downtime.',
    },
    {
      icon: <ClockArrowUp />,
      title: '24/7 Expert Support',
      description: 'Enjoy peace of mind with our dedicated support team available 24/7. Whether you need technical assistance or have questions, we’re here to help you at any time, day or night.',
    },
  ];